import dynamic from 'next/dynamic';

const PartnerCard = dynamic(import(/* webpackChunkName 'partnercard' */'@/components/partner/card.js'));
const Select = dynamic(import(/* webpackChunkName: `coreselect` */'@/core/select.js'));
const DropDown = dynamic(import(/* webpackChunkName: `coredropdown` */'@/core/dropdown.js'));
const MarkdownWithHtml = dynamic(import(/* webpackChunkName: 'markdown' */ 'markdown-to-jsx'));

const SpaceCardSkin1 = dynamic(import(/* webpackChunkName: `spacecardskin1` */'@/components/space/card-skin1.js'));
const SpaceCardSkinHome = dynamic(import(/* webpackChunkName: `spacecardskinhome` */'@/components/space/card-skin-home.js'));
const SpaceCardSkinCities = dynamic(import(/* webpackChunkName: `spacecardskincities` */'@/components/space/card-skin-cities.js'));
const SpaceCardSkinCorpo = dynamic(import(/* webpackChunkName: `spacecardskincorpo` */'@/components/space/card-skin-corpo.js'));

const Input = dynamic(import(/* webpackChunkName 'coreinput' */'@/core/input'), { ssr: false });
const Button = dynamic(import(/* webpackChunkName 'corebutton' */'@/core/button'));
const Search = dynamic(import(/* webpackChunkName: `coresearch` */'@/core/search'));
const YesNo = dynamic(import(/* webpackChunkName: `coresearch` */'@/core/yesno'));
const HeaderConfirm = dynamic(import(/* webpackChunkName: `headerlogincorpocorpoconfirm` */'@/components/header/login/corpo/corpoConfirm'));
const HeaderCorpo = dynamic(import(/* webpackChunkName: `headerlogincorpocorpo` */'@/components/header/login/corpo/corpo'));
const HeaderLoginCorpo = dynamic(import(/* webpackChunkName: `headerlogincorpoindex` */'@/components/header/login/corpo/index.js'));
const HeaderRegisterCompany = dynamic(import(/* webpackChunkName: `headerlogincorporegister` */'@/components/header/login/corpo/registerCompany'));
const LoginSignupContainer = dynamic(import(/* webpackChunkName: `headercontainersignup` */'@/components/header/loginsignup'));
const HeaderLogin = dynamic(import(/* webpackChunkName: `headerlogin` */'@/components/header/login'));
const HeaderSignup = dynamic(import(/* webpackChunkName: `headersignup` */'@/components/header/signup'));
const HeaderSignupStep2 = dynamic(import(/* webpackChunkName: `headersignupstep2` */'@/components/header/signupstep2'));
const LayoutCard = dynamic(import(/* webpackChunkName: `headercontainersignup` */'@/components/corpo/layoutCard'));
const HeaderHelp = dynamic(import(/* webpackChunkName: `headerhelp` */'@/components/header/help.js'));
const HeaderBookmark = dynamic(import(/* webpackChunkName: `headerbookmark` */'@/components/header/bookmark.js'));
const PartnerResume = dynamic(import(/* webpackChunkName: `partnerresume` */'@/components/partner/resume'));
const BreadcrumCms = dynamic(import(/* webpackChunkName: `breadcrumcms` */'@/components/breadcrum/cms'));
const SpaceSimulator = dynamic(import(/* webpackChunkName: `spacesimulator` */'@/components/simulator/space'));
const HomeZonePlus = dynamic(import(/* webpackChunkName: `homezonespace` */'@/components/home/zonePlus'));
const HomeZoneSpacesPartners = dynamic(import(/* webpackChunkName: `homezonepartners` */'@/components/home/zoneSpacesPartners'));
const ActivitiesCard = dynamic(import(/* webpackChunkName: `headercontainersignup` */'@/components/corpo/activitiesCard'));
const ProfileCard = dynamic(import(/* webpackChunkName: `headerlogincorpoprofilecard` */'@/components/corpo/profileCard'));
const ParamsCard = dynamic(import(/* webpackChunkName: `headerlogincorpoparamscard` */'@/components/corpo/paramsCard'));
const TeamCard = dynamic(import(/* webpackChunkName: `headerlogincorpoteamcard` */'@/components/corpo/teamCard'));
const HomeZoneSpacesCities = dynamic(import(/* webpackChunkName: `homezonepartners` */'@/components/home/zoneSpacesCities'));

const SearchRiders = dynamic(import(/* webpackChunkName: `searchriders` */'@/components/fields/riders'), { ssr: false });
const SearchLoading = dynamic(import(/* webpackChunkName 'searchloading' */'@/components/search/search-loading'));
const SearchMap = dynamic(import(/* webpackChunkName: `searchmap` */'@/components/search/map.js'), { ssr: false });

const Avatar = dynamic(import(/* webpackChunkName: `avatar` */'@/core/avatar.js'));

const SpacePublicCartridge = dynamic(import(/* webpackChunkName: `spacepubliccartridge` */'@/components/space/public/cartridge'));
const SpacePublicMobileCartridge = dynamic(import(/* webpackChunkName: `spacepublicmobilecartridge` */'@/components/space/public/mobileCartridge'));
const SpacePublicContent = dynamic(import(/* webpackChunkName: `spacepubliccontent` */'@/components/space/public/content'));
const SpacePublicAmbiences = dynamic(import(/* webpackChunkName: `spacepublicambiences` */'@/components/space/public/static/ambiences'));
const SpacePublicStations = dynamic(import(/* webpackChunkName: `spacepublicstations` */'@/components/space/public/static/stations'));
const SpacePublicReviews = dynamic(import(/* webpackChunkName: `spacepublicreviews` */'@/components/space/public/static/reviews'));
const SpacePublicDescription = dynamic(import(/* webpackChunkName: `spacepublicdescription` */'@/components/space/public/static/description'));
const SpacePublicGoogle = dynamic(import(/* webpackChunkName: `spacepublicgoogle` */'@/components/space/public/static/google'));
const SpacePublicBookmark = dynamic(import(/* webpackChunkName: `spacepublicbookmark` */'@/components/space/public/static/bookmark'));
const SpacePublicCovid = dynamic(import(/* webpackChunkName: `spacepublicstatic` */'@/components/space/public/static/covid'));
const SpacePublicHostServices = dynamic(import(/* webpackChunkName: `spacepublichostservices` */'@/components/space/public/byCategory/servicesHost'));
const SpacePublicCapacity = dynamic(import(/* webpackChunkName: `spacepubliccategory` */'@/components/space/public/byCategory/capacity'));
const SpacePublicCancelPolicy = dynamic(import(/* webpackChunkName: `spacepublicpolicy` */'@/components/space/public/byCategory/cancelPolicy'));
const SpacePublicFormule = dynamic(import(/* webpackChunkName: `spacepublicformule` */'@/components/space/public/byCategory/formule'));
const SpacePublicEquipments = dynamic(import(/* webpackChunkName: `spacepublicequipment` */'@/components/space/public/byCategory/equipments'));
const SpacePublicEnvironments = dynamic(import(/* webpackChunkName: `spacepublicenvironment` */'@/components/space/public/byCategory/environments'));
const SpacePublicCalendar = dynamic(import(/* webpackChunkName: `spacepubliccalendar` */'@/components/space/public/byCategory/calendar'));
const SpacePublicSimilarSpaces = dynamic(import(/* webpackChunkName: `spacepublicsimilarspace` */'@/components/space/public/byCategory/similarSpaces'));
const SpacePublicPrimaryServices = dynamic(import(/* webpackChunkName: `spacepublicsimilarservices` */'@/components/space/public/byCategory/primaryServices'));
const SpacePublicPartners = dynamic(import(/* webpackChunkName: `spacepublicpartners` */'@/components/space/public/static/partners'));
const SpacePublicInternal = dynamic(import(/* webpackChunkName: `spacepublicinternal` */'@/components/space/public/static/internal'));
const SpacePublicDistrict = dynamic(import(/* webpackChunkName: `spacepublicdistrict` */'@/components/space/public/static/district'));
const SpacePublicTopPictures = dynamic(import(/* webpackChunkName: `spacepublicpictures` */'@/components/space/public/static/top_pictures'));

const LoginByEmail = dynamic(import(/* webpackChunkName: `loginbyemail` */'@/components/header/login/email'));
const LoginByGoogle = dynamic(import(/* webpackChunkName: `loginbygoogle` */'@/components/header/login/google'));
const LoginByFB = dynamic(import(/* webpackChunkName: `loginbyfb` */'@/components/header/login/facebook'));
const LoginByLk = dynamic(import(/* webpackChunkName: `loginbylk` */'@/components/header/login/linkedin'));

const Header = dynamic(import(/* webpackChunkName: `header` */'@/components/header/index.js'));
const Footer = dynamic(import(/* webpackChunkName: `footer` */'@/core/footer'));

export {
  Avatar,
  Header,
  HeaderConfirm,
  HeaderCorpo,
  HeaderLoginCorpo,
  HeaderLogin,
  HeaderRegisterCompany,
  HeaderSignup,
  LoginSignupContainer,
  HeaderSignupStep2,
  Footer,
  PartnerCard,
  Select,
  DropDown,
  MarkdownWithHtml,
  SpaceCardSkin1,
  SpaceCardSkinHome,
  SpaceCardSkinCities,
  SpaceCardSkinCorpo,
  Input,
  Search,
  YesNo,
  PartnerResume,
  BreadcrumCms,
  Button,
  HeaderBookmark,
  SpaceSimulator,
  HomeZonePlus,
  HomeZoneSpacesPartners,
  ActivitiesCard,
  ProfileCard,
  ParamsCard,
  TeamCard,
  LayoutCard,
  HomeZoneSpacesCities,
  LoginByEmail,
  LoginByGoogle,
  LoginByFB,
  LoginByLk,
  SearchRiders,
  SearchLoading,
  SearchMap,
  SpacePublicCartridge,
  SpacePublicMobileCartridge,
  SpacePublicAmbiences,
  SpacePublicStations,
  SpacePublicReviews,
  SpacePublicContent,
  SpacePublicCapacity,
  SpacePublicPartners,
  SpacePublicInternal,
  SpacePublicDistrict,
  SpacePublicHostServices,
  SpacePublicCancelPolicy,
  SpacePublicFormule,
  SpacePublicEquipments,
  SpacePublicEnvironments,
  SpacePublicCalendar,
  SpacePublicDescription,
  SpacePublicGoogle,
  SpacePublicSimilarSpaces,
  SpacePublicBookmark,
  SpacePublicCovid,
  SpacePublicPrimaryServices,
  SpacePublicTopPictures,
};
