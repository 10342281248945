/* eslint-disable import/prefer-default-export */
import create from 'zustand';

const useZusHomeStore = create((set) => ({
  headerBackground: '',
  setHeaderBackground2: (style) => set((state) => {
    const styles = {
      style1: 'logo-max text-dark-deep',
      style2: 'logo-min bg-white text-black',
      classDefault: state.classDefault,
    };

    let styleFinal = '';
    if (style === 'classDefault') styleFinal += styles.classDefault;
    else if (!style || !styles[style]) styleFinal += styles.style1;
    else styleFinal += styles[style];

    if (!state.noFixedHeader) styleFinal += ' fixed ';
    return { ...state, headerBackground: styleFinal };
  }),
}));

export {
  useZusHomeStore,
};

export default useZusHomeStore;
