import '../styles/globals.css';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import Head from 'next/head';
import ErrorPage from 'next/error';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import Script from 'next/script';
import {
  isMobile, isDesktop, isSafari, isChrome, isIE,
} from 'react-device-detect';
import { useZusUserConnectedStore } from '@/helpers/user-auth.js';
import { ZusModalProvider } from '@/helpers/modal.js';
import { useZusHomeStore } from '@/helpers/context';
import cookie from '@/helpers/cookie';
import Layout from '@/render/layout.js';
import LayoutBlank from '@/render/blank.js';
import {
  initEnvironment, cleanEnvironment,
} from '@/relay/index.js';
import { extWebpJpg } from '@/helpers/common.js';
import { useSearchZustandStore } from '@/helpers/searchctx.js';
import ErrorBoundary from '@/helpers/errorBoundary';
import { useZusTranslate } from '@/helpers/translate.js';
import CookieConsent from '@/core/consent.js';

function ScriptViewPort() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  useEffect(() => {
    window?.Intercom?.('update');
  }, [pathname, searchParams]);

  useEffect(() => () => {
      cleanEnvironment();
      // console.log('out and cleanEnvironment', pathname);
    }, [pathname]);

  return null;
}

function MyApp({
  Component, // eslint-disable-line react/prop-types
  pageProps: { // eslint-disable-line react/prop-types
    layout, // eslint-disable-line react/prop-types
    queryRecords, // eslint-disable-line react/prop-types
    statusCode, // eslint-disable-line react/prop-types
    animateHeader, // eslint-disable-line react/prop-types
    noFixedHeader, // eslint-disable-line react/prop-types
    noFooter, // eslint-disable-line react/prop-types
    linkedincode, // eslint-disable-line react/prop-types
    tradFromServer, // eslint-disable-line react/prop-types
    locale, // eslint-disable-line react/prop-types
    ...newPageProps // eslint-disable-line react/prop-types
  },
}) {
  if (linkedincode) return <Component />;
  const header = {};
  if (statusCode === 404 || !Object.entries(newPageProps).length) {
    return <ErrorPage statusCode={404} />;
  }

  if (animateHeader) header.classDefault = 'logo-max text-dark';
  header.noFixedHeader = false;
  if (noFixedHeader) header.noFixedHeader = true;

  const authenticated = cookie.isConnected();

  if (!authenticated) cookie.removeTokenConnection();

  header.ua = {
    isMobile, isDesktop, isSafari, isChrome, isIE,
  };
  header.extWebpJpg = extWebpJpg(header.ua);

  let ComponentLayout = Layout;
  if (layout === 'blank') ComponentLayout = LayoutBlank;

  if (newPageProps?.componentPage === 'search') {
    const tourExist = cookie.get('isTourCookie');
    useSearchZustandStore.setState({
      initParams: newPageProps?.variables,
      category: newPageProps?.variables.category,
      isTour: !tourExist,
    });
  }

  useZusHomeStore.setState({ ...header });
  const { setHeaderBackground2 } = useZusHomeStore.getState();
  if (header.classDefault) setHeaderBackground2('classDefault');
  else setHeaderBackground2('style2');

  useZusUserConnectedStore.setState({
    isAuthenticated: authenticated,
    comefrom: newPageProps.componentPage,
  });

  useZusTranslate.getState().setTradData(tradFromServer, locale);

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/imgv4/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/imgv4/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/imgv4/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/imgv4/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2974ff" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1" />
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://metrics.officeriders.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-T6T4HV');
            `,
          }}
        /> */}
      </Head>
      <ErrorBoundary>
        <ScriptViewPort />
        <RelayEnvironmentProvider environment={initEnvironment({ records: queryRecords })}>
          <ComponentLayout {...{ animateHeader, noFooter }}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...newPageProps} />
            <CookieConsent />
            <div id="custom_intercom" className={`z-10 cursor-pointer fixed bg-blue hover:bg-blue-dark right-4 bottom-20 lg:bottom-16 lg:right-16 h-12 w-12 md:h-14 md:w-14 rounded-full shadow-lg flex items-center justify-center transition ease-in-out bg-blue-500 hover:scale-110 ${newPageProps.componentPage === 'space-public' || newPageProps.componentPage === 'search' ? 'hidden lg:flex' : ''}`}>
              <svg width="1em" height="1em" className="w-6 h-6 text-white fill-current md:w-7 md:h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
                <path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z" />
              </svg>
            </div>
          </ComponentLayout>
          <ZusModalProvider />
        </RelayEnvironmentProvider>
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
