// https://dev.to/justincy/detecting-authentication-client-side-in-next-js-with-an-httponly-cookie-when-using-ssr-4d3e
// https://codesandbox.io/s/qlypzkx71j?file=/src/index.js

/* eslint-disable react-hooks/rules-of-hooks */

import create from 'zustand';

const useZusUserConnectedStore = create((set) => ({
  checkAtLeastOnce: false,
  isAuthenticated: false,
  userData: null,
  what: null,
  comefrom: null,
  setZusUserData: (userData) => set((state) => ({ ...state, userData })),
  setAuthenticated: (isAuthenticated) => set((state) => ({ ...state, isAuthenticated })),
  setDisconnect: () => set((state) => ({ ...state, userData: null, isAuthenticated: false })),
  userAuthOpenModal: (what) => set((state) => ({ ...state, what })),
}));

export {
  useZusUserConnectedStore,
};

export default useZusUserConnectedStore;
