/* eslint-disable no-return-assign */
import {
  Environment,
  Network,
  QueryResponseCache,
  RecordSource,
  Store,
} from 'relay-runtime';
import Error from 'next/error';
import cookie from '@/helpers/cookie.js';

const oneMinute = 5 * 60 * 1000;
const cache = new QueryResponseCache({ size: 250, ttl: oneMinute });
// let environment = null;

// eslint-disable-next-line default-param-last
async function fetchServerQL(text, variables = {}, token) {
  const headers = {};
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  const response = await fetch(process.env.NEXT_PUBLIC_API_GRAPHQL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-client-name': 'web-private',
      'x-client-version': `1.0-${process.env.NEXT_PUBLIC_CLIENT_CUSTOM_VERSION}`,
      ...headers,
    },
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });

  // Get the response as JSON
  return response.json();
}

function fetchQuery(operation, variables, cacheConfig) {
  const queryID = operation.text;
  const isMutation = operation.operationKind === 'mutation';
  const isQuery = operation.operationKind === 'query';
  const forceFetch = cacheConfig && cacheConfig.force;
  const fromCache = cache.get(queryID, variables);
  const noCache = cacheConfig && cacheConfig.noCache;

  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }
  const headers = {
    'Content-Type': 'application/json',
    'x-client-name': 'webapp',
    'x-client-version': 'v3@Tom',
  };
  try {
    const token = cookie.getTokenConnection();
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
  } catch (e) {
    console.log(e);
  }

  return fetch(
    process.env.NEXT_PUBLIC_API_GRAPHQL,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
      cache: 'no-store',
    },
  )
    .then((response) => response.json())
    .then((json) => {
      if (isQuery && json && !noCache) {
        cache.set(queryID, variables, json);
      }
      if (isMutation) {
        cache.clear();
      }

      return json;
    }).catch((e) => {
      console.log(e);
      throw new Error('graphql say fuck');
    });
}

let currentEnvironment;
const initEnvironment = ({ records = {} } = {}) => { // eslint-disable-line max-len, import/prefer-default-export
  let newRecords = records;
  if (!records || !Object.entries(records).length) newRecords = {};
  const network = Network.create(fetchQuery);
  const store = new Store(new RecordSource(newRecords));

  // if (currentEnvironment) {
    // return currentEnvironment;
  // }

  // console.log('recordsrecordsrecords', records);
  currentEnvironment = new Environment({
    network,
    store,
  });

  return currentEnvironment;
};

const cleanEnvironment = () => {}; // currentEnvironment = null;
export { initEnvironment, fetchServerQL, cleanEnvironment };
export default initEnvironment;
